<svg
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
  viewBox="0 0 271.3 258"
  width="100%"
  class={$$props.class || ""}
>
  <style type="text/css">
    .st0 {
      fill: #00b67a;
    }
    .st1 {
      fill: #005128;
    }
  </style>
  <g id="Type"> </g>
  <g id="Star">
    <g>
      <polygon
        class="st0"
        points="271.3,98.6 167.7,98.6 135.7,0 103.6,98.6 0,98.5 83.9,159.5 51.8,258 135.7,197.1 219.5,258
       187.5,159.5 271.3,98.6 271.3,98.6 271.3,98.6 		"
      >
      </polygon>
      <polygon class="st1" points="194.7,181.8 187.5,159.5 135.7,197.1 		"> </polygon>
    </g>
  </g>
</svg>
