<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 94 94" width="100%" class={$$props.class || ""}>
  <path
    d="M46.75 93.7C72.4312 93.7 93.25 72.8812 93.25 47.2C93.25 21.5187 72.4312 0.699951 46.75 0.699951C21.0688 0.699951 0.25 21.5187 0.25 47.2C0.25 72.8812 21.0688 93.7 46.75 93.7Z"
    fill="#51039E"
  />
  <mask id="mask0_350_736" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="94" height="94">
    <path
      d="M46.75 93.7C72.4312 93.7 93.25 72.8812 93.25 47.2C93.25 21.5187 72.4312 0.699951 46.75 0.699951C21.0688 0.699951 0.25 21.5187 0.25 47.2C0.25 72.8812 21.0688 93.7 46.75 93.7Z"
      fill="#9ECF3E"
    />
  </mask>
  <g mask="url(#mask0_350_736)">
    <path
      d="M46.7499 19.3C57.0224 19.3 65.3499 10.9725 65.3499 0.699974C65.3499 -9.57252 57.0224 -17.9 46.7499 -17.9C36.4774 -17.9 28.1499 -9.57252 28.1499 0.699974C28.1499 10.9725 36.4774 19.3 46.7499 19.3Z"
      fill="#A4D9EB"
    />
    <path
      d="M28.1499 56.5C28.1499 51.567 30.1095 46.836 33.5977 43.3478C37.0859 39.8597 41.8169 37.9 46.7499 37.9C51.6829 37.9 56.4139 39.8597 59.9021 43.3478C63.3903 46.836 65.3499 51.567 65.3499 56.5V103H28.1499V56.5Z"
      fill="#A4D9EB"
    />
  </g>
</svg>
